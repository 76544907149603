.openButton {
  background-color: var(--color-palette-grey-800) !important;
  border: none;
  border-radius: 24px;
  bottom: 16px;
  position: fixed;
  right: 16px;
  z-index: 1;

  &:hover {
    background-color: var(--color-palette-grey-800) !important;
  }

  .icon {
    color: var(--color-text-light-active) !important;
  }
}

.label {
  color: var(--color-text-light-active) !important;
}