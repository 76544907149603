@import 'theme/utils.scss';

.page {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100%;
  overflow-x: hidden;

  &.hasFooterOnly {
    grid-template-rows: 1fr auto;
  }

  &.hasHeaderOnly {
    grid-template-rows: auto 1fr;
  }

  .container {
    height: 100%;
    max-width: 100vw;
    padding: 24px 0;
    position: relative;

    @include ScreenSize('small') {
      padding: 15px;
    }
  }
}
