@import 'theme/utils.scss';

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;

  &.noMargin {
    margin-left: 0;
    margin-right: 0;
  }

  &.right {
    justify-content: right;
  }

  &.center {
    justify-content: center;
  }

  .lgdisabled {
    display: none;
  }

  .ptlg0 {
    padding-top: 0;
  }

  .pblg0 {
    padding-bottom: 0;
  }

  @include ViewPort('large') {
    .mddisabled {
      display: none;
    }

    .ptmd0 {
      padding-top: 0;
    }

    .pbmd0 {
      padding-bottom: 0;
    }
  }

  @include ViewPort('middle') {
    .smdisabled {
      display: none;
    }

    .ptsm0 {
      padding-top: 0;
    }

    .pbsm0 {
      padding-bottom: 0;
    }
  }

  @include ViewPort('extraSmall') {
    padding: 0;

    .xsdisabled {
      display: none;
    }

    .ptxs0 {
      padding-top: 0;
    }

    .pbxs0 {
      padding-bottom: 0;
    }
  }
}

.clickable {
  cursor: pointer;
}

.col {
  padding: 12px;

  &.justify-center {
    justify-content: center;
  }

  &.justify-left {
    justify-content: flex-start;
  }

  &.justify-right {
    justify-content: flex-end;
  }

  &.flex {
    display: flex;
  }
}

@mixin cols($i, $m) {
  $s: $m * $i;

  .lg#{$i} {
    display: initial;
    width: $s;
  }

  .row {
    @include ViewPort('superLarge') {
      .sl#{$i} {
        padding-top: #{$s}px;
      }
    }
    @include ViewPort('large') {
      .md#{$i} {
        width: $s;
      }
    }

    @include ViewPort('middle') {
      .sm#{$i} {
        width: $s;
      }
    }

    @include ViewPort('extraSmall') {
      .xs#{$i} {
        width: $s;
      }
    }
  }
}

@mixin paddings($i, $m) {
  $p: $i * $m;

  .ptlg#{$i} {
    padding-top: #{$p}px;
  }

  .pblg#{$i} {
    padding-bottom: #{$p}px;
  }

  div {
    @include ViewPort('superLarge') {
      .ptsl#{$i} {
        padding-top: #{$p}px;
      }

      .pbsl#{$i} {
        padding-bottom: #{$p}px;
      }
    }

    @include ViewPort('large') {
      .ptmd#{$i} {
        padding-top: #{$p}px;
      }

      .pbmd#{$i} {
        padding-bottom: #{$p}px;
      }
    }

    @include ViewPort('middle') {
      .ptsm#{$i} {
        padding-top: #{$p}px;
      }

      .pbsm#{$i} {
        padding-bottom: #{$p}px;
      }
    }

    @include ViewPort('extraSmall') {
      .ptxs#{$i} {
        padding-top: #{$p}px;
      }

      .pbxs#{$i} {
        padding-bottom: #{$p}px;
      }
    }
  }
}

@for $i from 1 through 12 {
  @include cols($i, 8.3333%);
}

@for $i from 1 through 50 {
  @include paddings($i, 10);
}
