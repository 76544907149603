@import 'theme/colors.scss';

@mixin ScreenSize($type: 'extraLarge') {
  @if $type == 'extraLarge' {
    @media screen and (min-width: map-get($screenSizes, large)) {
      @content;
    }
  } @else if $type == 'large' {
    @media screen and (max-width: map-get($screenSizes, large)) {
      @content;
    }
  } @else if $type == 'normal' {
    @media screen and (max-width: map-get($screenSizes, normal)) {
      @content;
    }
  } @else if $type == 'small' {
    @media screen and (max-width: map-get($screenSizes, small)) {
      @content;
    }
  } @else if $type == 'extraSmall' {
    @media screen and (max-width: map-get($screenSizes, extraSmall)) {
      @content;
    }
  }
}

@mixin ViewPort($type) {
  @if $type == 'superLarge' {
    @media screen and (min-width: 1980px) {
      @content;
    }
  } @else if $type == 'extraLarge' {
    @media screen and (max-width: 1200px) {
      @content;
    }
  } @else if $type == 'large' {
    @media screen and (max-width: 1280px) {
      @content;
    }
  } @else if $type == 'middle' {
    @media screen and (max-width: 1024px) {
      @content;
    }
  } @else if $type == 'almostSmall' {
    @media screen and (max-width: 860px) {
      @content;
    }
  } @else if $type == 'small' {
    @media screen and (max-width: 800px) {
      @content;
    }
  } @else if $type == 'extraSmall' {
    @media screen and (max-width: 600px) {
      @content;
    }
  } @else if $type == 'smallHeight' {
    @media screen and (max-height: 700px) {
      @content;
    }
  }
}
