@import '~@beeline/design-tokens/scss/tokens/globals/_palettes.scss';

/** screen sizes **/
$screenSizes: (
  extraLarge: 1920px,
  large: 1440px,
  normal: 1024px,
  small: 598px,
  extraSmall: 360px,
);

/** main colors */
$primary: #f0b900;
$secondary: #212121;

$light: #fff;
$dark: rgba(0, 0, 0, 0.87);
$modal: rgba(0, 0, 0, 0.68);
$border: rgba(0, 0, 0, 0.18);
$textLight: rgba(255, 255, 255, 0.87);
$textFade: rgba(255, 255, 255, 0.6);
$link: $color-palette-blue-600;
$textDarkOpacity: rgba(0, 0, 0, 0.6);
$askQuestionBanner: rgba(26, 115, 232, 0.12);
$colorsGradient: linear-gradient(75.63deg, #fa1c8c 3.64%, #527ce7 46.99%, #2ebacf 96.07%);