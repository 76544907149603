@import '~@beeline/design-tokens/scss/mixin';

.logo {
  align-items: center;
  display: inline-flex;
  text-decoration: none;

  .events {
    align-items: center;
    color: var(--color-text-inactive);
    display: inline-flex;
    font-weight: unset;
    @include productName;
  }

  .icon {
    height: 25px;
    margin-left: 20px;
    width: 105px;
  }
}
