.icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-flex;

  &.clickable {
    cursor: pointer;
  }

  .svg {
    fill: none;

    > * {
      fill-opacity: inherit;
      fill-rule: inherit;
    }
  }
}
