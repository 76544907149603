@import 'theme/utils.scss';

.footer {
  background-color: var(--color-border-focus);
  left: 0;
  min-height: 156px;
  overflow: hidden;
  width: 100%;

  @include ScreenSize('normal') {
    min-height: 174px;
  }

  @include ScreenSize('small') {
    min-height: 182px;
  }

  @include ScreenSize('extraSmall') {
    min-height: 276px;

    &.absolute {
      position: fixed;
    }
  }

  .inner {
    margin: 0 auto;
    padding: 32px 0;

    @include ScreenSize('small') {
      padding: 16px 0;
    }
  }

  .link {
    color: var(--color-text-link);
    text-decoration: none;
  }

  .text {
    &:first-child {
      color: var(--color-text-active-inverse);
    }
    &:last-child {
      color: var(--color-text-inactive-inverse);
      padding-top: 16px;
    }
  }
}
