@import 'theme/utils.scss';

.program {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  margin-top: 80px;
  position: relative;

  @include ScreenSize('small') {
    margin-top: 40px;
  }

  .list {
    border-top: 1px solid var(--color-divider);
    margin-top: 41px;
    padding: 56px 80px 0 80px;

    @include ScreenSize('small') {
      padding: 40px 0 0 0;
    }
  }

  .item {
    display: flex;
    justify-content: center;
    margin-bottom: 56px;
    width: 100%;

    @include ScreenSize('small') {
      flex-direction: column;
      margin-bottom: 40px;
    }

    .block {
      display: inline-flex;
      flex-direction: column;
      margin-left: 80px;
      width: 100%;

      @include ScreenSize('small') {
        margin: 12px 0 0 0;
      }

      .description {
        margin-top: 16px;

        a {
          color: $link;
        }
      }

      .speakers {
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        row-gap: 24px;

        .speaker {
          margin-top: 24px;
        }
      }
    }
  }

  .subtitle {
    text-align: center;

    @include ScreenSize('small') {
      font-size: var(--font-size-h4);
    }

    strong {
      background: linear-gradient(to top, var(--od-color-mark) 0.53em, transparent 0.3em) no-repeat;
      background-position-y: -0.2em;
    }

    a {
      color: $link;
    }

  }

  .moderators {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;

    .moderator {
      margin-top: 33px;
    }
  }

  .time {
    min-width: 140px;
  }

  .title {
    margin-bottom: 32px;
    text-align: center;
  }
}