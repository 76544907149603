.question {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  padding-bottom: 0px;

  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .questionHeader {
      align-items: center;
      display: flex;
      gap: 8px;
      justify-content: center;

      .avatar {
        border-radius: 12px;
        height: 40px;
        width: 40px;
      }

      .nameQuestioner {
        color: var(--color-text-active);
      }
    }

    .time {
      align-items: center;
      display: flex;
    }
  }

  .questionAddressee {
    display: flex;
    gap: 8px;

    .whom {
      color: var(--color-text-inactive);
    }

    .whomName {
      color: var(--color-text-link);
    }
  }

  .favoriteBlock {

    align-items: center;
    display: flex;
    gap: 5px;
    justify-content: right;
    padding-bottom: 8px;

    .favorite {
      cursor: pointer;
    }

  }
}