@import 'theme/utils.scss';

.participant {
  display: inline-flex;
  justify-content: flex-start;

  &.small {
    .image {
      height: 80px;
      width: 80px;
    }
  }

  .image {
    border-radius: 50%;
    height: 120px;
    width: 120px;

    @include ScreenSize('small') {
      height: 88px;
      width: 88px;
    }
  }

  .info {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    margin-left: 24px;

    &.topAlign {
      justify-content: flex-start;
    }

    &.centerAlign {
      justify-content: center;
    }

    @include ScreenSize('small') {
      margin-left: 24px;
    }
  }

  .role {
    background: var(--color-palette-blue-600);
    border-radius: 12px;
    color: var(--color-text-active-inverse);
    padding: 2px 8px;
  }

  .text {
    margin-bottom: 4px;

    &:last-child {
      color: var(--color-text-inactive);
      margin-bottom: 0;
    }
  }
}