@import 'theme/utils.scss';
@import 'theme/colors.scss';

.root {
  background: $light;
  border-radius: 12px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1), 0px 4px 30px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
  padding: 24px;

  .inner {
    display: flex;
  }

  .preview {
    align-items: center;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    margin-right: 40px;
    max-height: 120px;
    max-width: 120px;
    min-height: 120px;
    min-width: 120px;
    overflow: hidden;

    >img {
      border-radius: 12px;
      display: block;
      margin: auto;
      max-width: 120px;
    }
  }

  .header {
    display: flex;
  }

  .title {
    display: block;
    margin-bottom: 16px;
  }

  .text {
    color: $textDarkOpacity;
  }

  .content {
    width: 100%;
  }

  .link {
    display: flex;
    margin-top: 24px;
    text-decoration: none;
  }

  @include ViewPort('small') {
    .inner {
      display: block;
    }

    .preview {
      height: 136px;
      margin-bottom: 16px;
      min-width: 100%;
      position: relative;

      >img {
        display: block;
        margin-top: -30%;
        min-width: 100%;
      }
    }

    .link {
      justify-content: flex-end;
    }
  }
}