@import 'theme/utils.scss';

.newQuestion {
  align-items: end;
  position: absolute;

  :global(.lk-ui_dialog-backdrop) {
    position: absolute;
  }

  .dialogContent {
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow: auto;
    position: relative;
    width: 100%;

    :global(.lk-ui_textarea) {
      resize: none;
    }

    @include ScreenSize('small') {
      border-radius: 0px;
      height: 100%;
    }


    .modalFooter {
      display: flex;
      gap: 16px;
      justify-content: flex-end;
    }
  }
}