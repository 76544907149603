@import 'theme/utils.scss';

.header {
  align-items: center;
  background: var(--color-background-base);
  border-bottom: 1px solid var(--color-background-base-focused);
  display: inline-flex;
  max-width: 100vw;
  min-height: 60px;
  padding: 0 24px;
  width: 100%;

  @include ScreenSize('small') {
    justify-content: space-between;
  }

  .desktop {
    display: inline-flex;
    height: 100%;
    @include ScreenSize('small') {
      display: none;
    }
  }

  .mobile {
    display: none;
    position: relative;

    @include ScreenSize('small') {
      display: inline-flex;
    }

    .hamburger {
      cursor: pointer;
      display: inline-flex;
      height: 24px;
      outline: none;
      width: 24px;
    }

    .menu {
      padding: 21px 16px;
      position: absolute;
      right: -10px;
      top: 34px;
      z-index: 1;
    }
  }

  .route {
    display: flex;
    height: 100%;
    margin-left: 44px;

    > a {
      align-items: center;
      display: flex;
      > p {
        > span {
          color: $textDarkOpacity;
        }
      }
      > p {
        &:hover {
          background-color: $light;
          > span {
            color: $dark;
          }
        }
      }
    }

    &.active {
      position: relative;
      > a {
        color: $dark;
        > p {
          > span {
            color: $dark;
          }
        }
      }
      &:before {
        background: $primary;
        border-radius: 4px 4px 0 0;
        bottom: 0;
        content: '';
        display: block;
        height: 4px;
        position: absolute;
        width: 100%;
      }
    }

    &:before {
      content: '';
      display: none;
    }

    @include ScreenSize('small') {
      margin: 0 0 26px 0;
      padding: 0;

      &.active {
        &:before {
          content: '';
          display: none;
        }

        .linkText {
          color: var(--color-text-disabled);
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .link {
    cursor: pointer;

    @include ScreenSize('small') {
      width: 202px;
    }

    .linkText {
      color: var(--color-text-inactive);

      @include ScreenSize('small') {
        color: var(--color-text-active);
        font-size: var(--font-size-body2);
        font-weight: var(--font-weight-regular);
      }
    }
  }
}
