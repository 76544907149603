@use '~@beeline/design-tokens/scss/tokens/themes';
@import '~@beeline/design-tokens/scss/tokens/themes/_dark';

@import 'theme/colors.scss';

:global {
  html {
    height: 100%;
    overflow-x: hidden;
    width: 100%;
  }
}

:root {
  @include themes.theme();

  .inverseTheme {
    @include themes.theme($theme);
  }

  --od-color-info: #722fcd;
  --od-color-mark: #fec900;

  --od-screen-extra-large: #{map_get($screenSizes, extraLarge)};
  --od-screen-large: #{map_get($screenSizes, large)};
  --od-screen-normal: #{map_get($screenSizes, normal)};
  --od-screen-small: #{map_get($screenSizes, small)};
  --od-screen-extra-small: #{map_get($screenSizes, extraSmall)};
}

body {
  background: var(--bu-light-accent);
}

html {
  -ms-overflow-style: none;
  scroll-behavior: auto;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

.lock {
  overflow: hidden;
}

// TODO temporary fix, lk-ui select should provide className for dropdownMenu
#popups {
  .speakerOption {
    display: flex;
    gap: 12px;

    .speakerAvatar {
      border-radius: 300px;
      height: 40px;
      width: 40px;
    }

    .speakerInfo {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .speakerPosition {
        color: var(--color-text-inactive);
        white-space: normal;
        word-break: break-word;
      }
    }
  }
}