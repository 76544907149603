.AudioPlayer {
  height: 100%;
  position: relative;
  width: 100%;
}

.Image {
  height: 100%;
  object-fit: contain;
  width: 100%;
}

.PlayButtonContainer {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.PlayButton {
  background: rgba(0, 0, 0, 0.54) no-repeat center;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  height: 64px;
  width: 64px;
}

.HiddenAudio {
  opacity: 0;
  visibility: hidden;
}
