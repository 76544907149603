@import 'theme/utils.scss';

.root {
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0 35px 0px 35px;
  position: relative;
  .inner {
    margin: 0 auto;
    max-width: 1536px;
    min-height: 650px;
    padding-bottom: 190px;
    &.fixed {
      align-items: center;
      display: flex;
    }
  }
}

@include ViewPort('small') {
  .root {
    padding: 0 32px 212px 32px;
  }
}

@include ViewPort('extraSmall') {
  .root {
    padding: 0 15px 0 15px;
    &.mh {
      min-height: inherit;
    }
    .inner {
      padding-bottom: 270px;
    }
  }
}
