@import 'theme/utils.scss';

.bubbleInfo {
  display: inline-flex;
  margin-top: 80px;
  position: relative;
  width: 100%;

  @include ScreenSize('small') {
    margin-top: 63px;
  }

  .card {
    background: var(--color-palette-amber-50);
    padding: 40px;
    width: 100%;

    * > a {
      color: $link;
    }

    @include ScreenSize('small') {
      margin-left: 0;
      padding: 40px 16px;
    }
  }
}