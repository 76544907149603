@import 'theme/colors.scss';
@import 'theme/utils.scss';

.archive {
  .archiveContainer {
    margin: 0 auto;
    max-width: 900px;
  }
  .title {
    margin-bottom: 24px;
  }
}
