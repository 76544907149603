@import 'theme/utils.scss';

.eventCard {
  border: none;
  overflow: hidden;
  padding: 0 !important;
  position: relative;
  width: 100% !important;

  .content {
    display: inline-flex;
    height: 100%;
    justify-content: flex-start;
    left: 0;
    position: absolute;
    width: 100%;

    &.leftAlign {
      justify-content: flex-start;
    }

    &.middleAlign {
      justify-content: center;
    }

    &.rightAlign {
      justify-content: flex-end;
    }

    @include ScreenSize('small') {
      justify-content: center !important;
    }
  }

  .controls {
    align-items: flex-start;
    align-self: flex-end;
    display: inline-flex;
    flex-direction: column;
    padding: 5%;

    @include ScreenSize('normal') {
      padding-bottom: 3%;
      padding-left: 3%;
    }

    @include ScreenSize('small') {
      align-items: center;
      padding-bottom: 3%;
    }

    .blackButton {
      background-color: rgba(20, 22, 34, 1);
      border: none;
      color: white;
    }
  }

  .image {
    height: 100%;
    max-width: 100%;
    min-height: 370px;
    object-fit: cover;
    width: 100%;
    z-index: -1;
  }
}