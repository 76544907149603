.questionsBody {
  height: calc(100% - var(--tab-height) - var(--footer-height));
  overflow: auto;
  padding-bottom: 10px;
  width: 100%;

  .noQuestions {
    align-items: center;
    color: var(--color-text-inactive);
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }
}

.footer {
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: center;
  width: 100%;
}