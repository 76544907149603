@import 'theme/utils.scss';
@import 'theme/colors.scss';

.root {
  .archiveTitle {
    height: 24px;
    margin-bottom: 38px;
    width: 80px;
  }
}
.item {
  background: $light;
  border-radius: 12px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1), 0px 4px 30px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
  padding: 24px;
  .inner {
    display: flex;
    width: 100%;
  }
  .preview {
    border-radius: 12px;
    margin-right: 40px;
    min-height: 120px;
    min-width: 120px;
    overflow: hidden;
  }
  .header {
    display: flex;
    width: 100%;
  }
  .title {
    display: block;
    margin-bottom: 12px;
    min-height: 16px;
    min-width: 250px;
  }
  .text {
    color: $textDarkOpacity;
  }
  .content {
    padding-left: 40px;
    width: 100%;
  }
  .link {
    display: flex;
    margin-left: auto;
    margin-right: 0;
    min-height: 16px;
  }
  .mobileLink {
    display: none;
  }
  @include ViewPort('small') {
    .inner {
      display: block;
    }
    .content {
      padding-left: 0;
    }
    .preview {
      height: 136px;
      margin-bottom: 16px;
      position: relative;
      width: 100%;
    }
    .title {
      margin-bottom: 0;
    }
    .link {
      display: none;
    }
    .mobileLink {
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
      margin-right: 0;
      margin-top: 26px;
      min-height: 16px;
    }
  }
}
