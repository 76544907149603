@import 'theme/colors.scss';
@import 'theme/utils.scss';

.root {
  background-color: $modal;
  display: flex;
  height: 100%;
  left: 0;
  padding: 5% 10%;
  position: fixed;
  top: 0;
  width: 100%;

  .close {
    cursor: pointer;
    position: absolute;
    right: 26px;
    top: 26px;
  }

  .inner {
    display: flex;
    gap: 24px;
    height: 100%;
    width: 100%;

    .player {
      align-items: center;
      background-color: black;
      border: 0;
      border-radius: 12px;
      display: flex;
      height: 100%;
      justify-content: center;
      overflow: hidden;
      position: relative;
      width: 100%;

      >iframe {
        border: 0;
        border-radius: 0;
      }
    }

    .popup {
      background-color: $light;
      border: 0;
      border-radius: 12px;
      height: 100%;
      max-width: 508px;
      width: 100%;
    }
  }

  @include ViewPort('middle') {
    .inner {
      flex-direction: column;
    }

    .popup {
      max-width: initial !important;
    }

    .close {
      right: 13px;
      top: 13px;
    }
  }

  @include ScreenSize('small') {
    .inner {
      justify-content: center;
    }

    .player {
      max-height: 278px !important;
    }

    .popup {
      border-radius: 0 !important;
      height: 100% !important;
      left: 0;
      position: fixed;
      top: 0;
      width: 100% !important;
    }
  }

  .btnLink {
    color: initial;
    text-decoration: inherit;
  }
}