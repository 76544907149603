.errorContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10%;
}

.errorImage {
  height: 160px;
  width: 160px;
}

.errorText {
  margin-top: 50px;
}

.errorButton {
  font-weight: unset;
  margin-top: 20px;
}
