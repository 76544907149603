@import 'theme/utils.scss';

.popup {
  --footer-height: 80px;
  --tab-height: 49px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08), 0px 6px 38px rgba(0, 0, 0, 0.16);
  min-width: 290px;
  overflow: hidden;
  overflow-x: auto;

  .popupHeader {
    display: flex;

    .tabs {
      width: 100%;
    }

    .closeButton {
      display: flex;
      flex-direction: column;
      width: 40px;

      .icon {
        height: 100%;
      }
    }
  }



  .chatBody {
    border: none;
    border-radius: 0 0 12px 12px;
    height: calc(100% - var(--tab-height));
    width: 100%;

    @include ScreenSize('small') {
      border-radius: 0px;
    }
  }

  .hide {
    display: none;
  }
}

.eventPopup {
  bottom: 16px;
  position: fixed;
  right: 16px;

  @include ScreenSize('small') {
    bottom: 0px;
    right: 0px;
  }
}

.broadcastingPopup {
  height: 100%;
  position: relative;
  width: 100%;
}