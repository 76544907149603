@import 'theme/utils.scss';

.popup {
  background-color: var(--color-background-base) !important;
  border-radius: 12px;
  height: 80%;
  width: 626px;

  @include ScreenSize('normal') {
    width: 506px;
  }

  @include ScreenSize('small') {
    border-radius: 0px;
    height: 100%;
    width: 100%;
  }

  .closeButton {
    position: absolute;
    right: 24px;
    top: 12px;
  }
}