@import 'theme/utils.scss';

.about {
  .block {
    padding-top: 30px;
  }

  .card {
    width: 100%;
  }
}