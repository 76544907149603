@import 'theme/colors.scss';
@import 'theme/utils.scss';

.home {
  &.ie {

    .background,
    .link {
      display: none;
    }

    .card {
      align-self: center;
    }
  }

  .background {
    position: absolute;
    right: 71px;
    width: 44.5%;
    z-index: -1;

    @include ScreenSize('normal') {
      display: none;
    }
  }

  .card {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    max-width: 496px;
    padding: 42px 88px;
    text-align: center;

    @include ScreenSize('large') {
      padding: 48px 56px;
    }

    @include ScreenSize('normal') {
      border: none;
    }

    @include ScreenSize('small') {
      border: 0;
      border: none;
      max-width: 100%;
      padding: 0;
      width: 100%;
    }

    .button,
    .link {
      width: 320px;

      @include ScreenSize('small') {
        width: 100%;
      }
    }

    .link {
      text-decoration: none;

      &.zoom {
        margin-bottom: 24px;
      }
    }

    .message {
      color: var(--color-text-inactive);

      &.error {
        color: var(--color-status-error);
      }

      &.ie,
      &.error {
        margin-bottom: 40px;
      }
    }

    .mobileId {
      margin: 42px 0 40px 0;
    }

    .name {
      margin-bottom: 24px;
      max-width: 260px;
    }
  }

  .content {
    justify-content: center;

    @include ScreenSize('normal') {
      align-items: center;
    }
  }
}