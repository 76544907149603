@import 'theme/colors.scss';
@import 'theme/utils.scss';

.root {
  border: 1px solid $border;
  border-radius: 12px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1), 0px 4px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 30px 54px;
  position: relative;
}

@include ViewPort('extraSmall') {
  .root {
    height: 667px;
    padding: 24px 16px;
  }
}
