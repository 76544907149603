@import 'theme/utils.scss';

.askQuestionBanner {
  display: inline-flex;
  margin-top: 40px;
  position: relative;
  width: 100%;

  @include ScreenSize('small') {
    margin-top: 24px;
  }

  .card {
    align-items: center;
    background: $askQuestionBanner;
    display: flex;
    gap: 32px;
    padding: 40px;
    width: 100%;

    .link {
      color: $link;
      cursor: pointer;
      text-decoration: none;
    }

    .button {
      height: fit-content;
      min-width: 203px;

      @include ScreenSize('small') {
        width: 100%;
      }
    }

    @include ScreenSize('small') {
      flex-direction: column;
      padding: 40px 16px;
    }
  }
}