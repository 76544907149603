@import 'theme/utils.scss';
@import 'theme/colors.scss';

.root {
  .banner {
    height: 540px;
    margin-bottom: 74px;
    width: 100%;
    @include ScreenSize('normal') {
      height: 370px;
      margin-bottom: 56px;
    }
    @include ScreenSize('small') {
      height: 320px;
      margin-bottom: 40px;
      margin-top: 24px;
    }
  }
  .skeleton {
    width: 100%;
  }
  .title {
    height: 16px;
    margin-bottom: 24px;
    width: 50%;
  }
  .text {
    height: 8px;
    margin-bottom: 24px;
    width: 100%;
    &:last-child {
      width: 80%;
    }
  }
}
